<template>
    <v-container v-if="artikkel">
        <v-row>
            <v-col cols="12" md="8" class="content">
                <template>
                    <h1>
                        <span v-html="artikkel.tittel"></span>
                        <div v-if="edit" class="text-subtitle-1 grey--text text--darken-1">
                            <em>{{ maalgruppe }}</em>
                        </div>
                    </h1>
                </template>

                <template v-if="!edit">
                    <div class="content__lead" v-html="artikkel.ingress"></div>

                    <div v-if="artikkel.bilde" class="content__image">
                        <v-img :aspect-ratio="16 / 9" :src="bildeUrl"></v-img>
                    </div>

                    <div v-if="artikkel.publisert" class="content__meta">
                        <div class="text-caption font-weight-medium mb-2">
                            <span>{{ artikkel.publisert | format('DD.MM.YYYY') }}</span>
                        </div>
                    </div>

                    <div class="content content__text">
                        <div v-html="artikkel.tekst"></div>
                    </div>

                    <div v-if="artikkel.vedlegg" class="d-flex flex-wrap justify-start my-4">
                        <v-chip
                            color="primary"
                            v-for="vedlegg in artikkel.vedlegg"
                            v-bind:key="vedlegg.filnavn"
                            class="mr-4 mb-2"
                            style="cursor: pointer"
                            v-on:click="downloadAttachment(vedlegg.filnavn)"
                        >
                            <v-icon left> mdi-file-document-outline </v-icon>
                            {{ vedlegg.filnavn }}
                        </v-chip>
                    </div>
                </template>
                <template v-else>
                    <v-tabs v-model="tab" class="v-tabs--content">
                        <v-tab>Innhold</v-tab>
                        <v-tab v-if="artikkel.id">Målgruppe</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <br />
                            <v-text-field v-model="artikkel.tittel" label="Overskrift" outlined hide-details></v-text-field>
                            <br />
                            <v-textarea v-model="artikkel.ingress" label="Ingress" auto-grow outlined></v-textarea>
                            <br />
                            <image-upload
                                v-if="artikkel.id"
                                width="100%"
                                height="100%"
                                :url="bildeUrl"
                                :uploading="uploading"
                                placeholder="mdi-account"
                                v-on:upload="uploadImage"
                            ></image-upload>
                            <br />
                            <v-card-text>
                                <date-field v-model="artikkel.publisert" label="Publiser(t)"></date-field>
                            </v-card-text>
                            <redactor v-model="artikkel.tekst"></redactor>
                            <attachments v-model="artikkel" label="Vedlegg" v-on:input="updatedAttachments"></attachments>
                        </v-tab-item>
                        <v-tab-item v-if="artikkel.id">
                            <tab-target v-model="artikkel" type="artikkel"></tab-target>
                        </v-tab-item>
                    </v-tabs-items>
                </template>
            </v-col>
            <v-col cols="12" md="4" v-if="canEdit">
                <v-card flat>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn icon v-if="!edit" @click="edit = true">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon v-if="edit" @click="save()">
                            <v-icon>mdi-content-save-edit</v-icon>
                        </v-btn>
                        <v-btn icon v-if="artikkel.id && edit" @click="remove()">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn icon v-if="edit" @click="cancel()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <template v-if="edit"> <br /><br /> </template>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
//import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';
import Redactor from '@/vendor/noop/components/Redactor.vue';
import ImageUpload from '@/vendor/noop/components/ImageUpload.vue';
import Attachments from '@/components/Attachments';
import TabTarget from '@/components/TabTarget';

export default {
    name: 'Article',
    components: {
        DateField,
        Redactor,
        ImageUpload,
        Attachments,
        TabTarget,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            artikkel: {
                id: null,
                tittel: '',
                ingress: '',
                tekst: '',
                publisert: null,
            },
            orginalArtikkel: null,

            bildeUrl: null,
            uploading: false,

            maalgruppe: '',

            tab: null,

            edit: false,
        };
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    computed: {
        ...mapGetters('api', ['hasRole']),
        canEdit: function () {
            return this.hasRole('ANSATT');
        },
    },
    watch: {
        edit: function (value) {
            if (value) {
                this.orginalArtikkel = Object.assign({}, this.artikkel);
            } else {
                if (this.orginalArtikkel) {
                    this.artikkel = this.orginalArtikkel;
                    this.orginalArtikkel = null;
                }
                this.hideRedactor();
            }
        },
    },
    /**
     * created
     */
    created: async function () {
        if (!this.id) {
            this.edit = true;
        } else {
            await this.load(this.id);
        }
    },
    methods: {
        ...mapActions('api', ['request', 'imageRequest', 'attachmentRequest', 'formatArticle', 'formatMaalgrupppe']),
        ...mapActions(['confirm', 'snackbar']),

        /**
         * load
         */
        load: async function (id) {
            const response = await this.request({
                method: 'get',
                url: '/artikkel/' + id,
            });

            if (response && typeof response.id != 'undefined') {
                await this.update(response);
            }
        },

        /**
         * update
         */
        update: async function (artikkel) {
            this.artikkel = await this.formatArticle(artikkel);
            if (this.artikkel) {
                const id = this.artikkel.id;
                this.bildeUrl = null;
                if (this.artikkel.bilde) {
                    this.bildeUrl = await this.imageRequest({
                        method: 'get',
                        url: '/artikkel/' + id + '/bilde',
                    });
                }
                this.maalgruppe = await this.formatMaalgrupppe(artikkel.maalgrupper);
            }
        },

        /**
         * updatedAttachments
         */
        updatedAttachments: function (artikkel) {
            this.artikkel.vedlegg = artikkel.vedlegg;
        },

        /**
         * save
         */
        save: async function () {
            const data = Object.assign({}, this.artikkel);

            if (data.publisert) {
                data.publisert = data.publisert.format('DD.MM.YYYY HH:mm');
            }

            const response = await this.request({
                method: 'post',
                url: '/artikkel' + (this.artikkel.id ? '/' + this.artikkel.id : ''),
                data: data,
            });

            if (response && typeof response.id != 'undefined') {
                const artikkel = response;
                if (this.artikkel.id) {
                    this.snackbar('Lagret');
                } else {
                    this.snackbar('Opprettet');
                    this.$router.push({ path: '/aktuelt/' + response.id });
                }
                this.orginalArtikkel = null;
                await this.update(artikkel);
            }
        },

        /**
         * remove
         */
        remove: async function () {
            if (await this.confirm({ title: 'Sikker?', message: 'Sikker på at du vil slette?' })) {
                if (this.id) {
                    const response = await this.request({
                        method: 'delete',
                        url: '/artikkel/' + this.id,
                    });
                    if (response === true) {
                        this.snackbar('Slettet');
                        this.$router.push({ path: '/aktuelt' });
                    }
                }
            }
        },

        /**
         * cancel
         */
        cancel: function () {
            if (this.artikkel.id) {
                this.edit = false;
            } else {
                this.$router.push({ path: '/aktuelt' });
            }
        },

        /**
         * uploadImage
         */
        uploadImage: async function (file) {
            const size = file.size ? file.size / (1000 * 1000) : null;
            if (size && size > 12) {
                console.log('Feil', 'Bildet er for stort (' + size.toPrecision(2) + ' MB). Det kan være maksimum 12 MB.');
            } else {
                const data = new FormData();
                data.append('image', file, file.name);

                const id = this.artikkel.id;

                this.uploading = true;

                const response = await this.request({
                    method: 'post',
                    url: '/artikkel/' + id + '/bilde',
                    data,
                });

                if (response && typeof response.id != 'undefined') {
                    this.artikkel.bilde = true;
                    this.bildeUrl = await this.imageRequest({
                        method: 'get',
                        url: '/artikkel/' + id + '/bilde',
                    });
                }
                this.uploading = false;
            }
        },

        /**
         * downloadAttachment
         */
        downloadAttachment: async function (filnavn) {
            await this.attachmentRequest({
                url: '/artikkel/' + this.artikkel.id + '/vedlegg/' + filnavn,
                filename: filnavn,
            });
        },

        /**
         * hideRedactor
         */
        hideRedactor: function () {
            setTimeout(() => {
                this.redactor = false;
                const textareas = this.$el.getElementsByTagName('textarea');
                if (textareas.length) {
                    const textarea = textareas[0];
                    setTimeout(() => {
                        textarea.remove();
                    }, 50);
                }
            }, 50);
        },
    },
};
</script>
