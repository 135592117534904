<template>
    <div class="d-flex flex-wrap justify-start my-4 attachments">
        <div v-if="label" class="caption" style="margin: 10px 0; width: 100%">
            {{ label }}
        </div>
        <v-chip
            v-for="vedlegg in artikkel.vedlegg"
            :key="vedlegg.id"
            close
            @click:close="removeAttachment(vedlegg)"
            v-on:click="downloadAttachment(vedlegg.filnavn)"
            class="mr-4 mb-4"
            color="primary"
        >
            <v-icon left> mdi-file-document-outline </v-icon>
            <span style="max-width: 220px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ vedlegg.filnavn }}</span>
        </v-chip>
        <div class="attachment-upload">
            <label for="documentInput">
                <input id="documentInput" type="file" @change="selectAttachment" />

                <v-chip color="primary">
                    <v-icon left> mdi-attachment </v-icon>
                    Last opp
                </v-chip>
            </label>
            <alert ref="alert"></alert>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Alert from '@/vendor/noop/components/Alert.vue';

export default {
    name: 'Attachments',

    components: {
        Alert,
    },

    /**
     * data
     */
    data() {
        return {
            artikkel: this.value !== null ? this.value : [],
        };
    },
    props: {
        value: {
            default: null,
            type: Object,
        },
        label: {
            default: null,
            ttype: String,
        },
    },

    /**
     * methods
     */
    methods: {
        ...mapActions('api', ['request', 'attachmentRequest', 'formatArticle']),

        /**
         * selectAttachment
         */
        selectAttachment: async function (e) {
            let file = e.target.files[0];
            if (file) {
                const size = file.size ? file.size / (1000 * 1000) : null;
                if (size && size > 12) {
                    this.$refs.alert.open('Feil', 'Dokumentet er for stort (' + size.toPrecision(2) + ' MB). Det kan være maksimum 12 MB.');
                } else {
                    const data = new FormData();
                    data.append('tittel', file.name);
                    data.append('fil', file);

                    try {
                        // artikkel
                        const response = await this.request({
                            method: 'post',
                            url: '/artikkel/' + this.artikkel.id + '/vedlegg',
                            data: data,
                        });

                        if (response && response.id) {
                            this.artikkel = await this.formatArticle(response);
                            this.$emit('input', this.artikkel);
                        } else {
                            await this.alert({ title: 'Feil', message: 'Det oppstod en ukjent feil.' });
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        },

        /**
         * removeAttachment
         */
        removeAttachment: async function (vedlegg) {
            try {
                const response = await this.request({
                    method: 'delete',
                    url: '/artikkel/' + this.artikkel.id + '/vedlegg/' + vedlegg.filnavn,
                });

                if (response && response.id) {
                    this.artikkel = await this.formatArticle(response);
                    this.$emit('input', this.artikkel);
                } else {
                    await this.alert({ title: 'Feil', message: 'Det oppstod en ukjent feil.' });
                }
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * downloadAttachment
         */
        downloadAttachment: async function (filnavn) {
            await this.attachmentRequest({
                url: '/artikkel/' + this.artikkel.id + '/vedlegg/' + filnavn,
                filename: filnavn,
            });
        },
    },
};
</script>
<style lang="scss">
.attachments {
    display: flex;
}
.attachment-upload {
    label {
        cursor: pointer;
    }

    input[type='file'] {
        height: 1px;
        width: 1px;
        visibility: hidden;
    }
}
</style>
